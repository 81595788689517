<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        Marchés > Tableau de bord
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Situation des sous-categories du programme "{{assignProgramme.programme.nom_programme}}"
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      <div class="div_left">
        <button class="btn btn-blue"
                @click="downloadExport(exportLink.url)"
                download>
          Exporter
        </button>
      </div>
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- /////////////////////////////////////// ZONE IMPRIMABLE /////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Situation_sous_categorie_programme_'+this.assignProgramme.programme.nom_programme+'_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-2">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center my-3">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des sous-categories du programme "{{assignProgramme.programme.nom_programme}}"
                du {{ annee_debut.split("-")[2]+"-"+annee_debut.split("-")[1]+"-"+annee_debut.split("-")[0] }}
                au {{ annee_fin.split("-")[2]+"-"+annee_fin.split("-")[1]+"-"+annee_fin.split("-")[0] }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table table-blue text-center"> 
            <thead>
              <tr>
                <th class="tht-dark-blue text-left"
                    scope="col">Catégorie</th>
                <th class="tht-dark-blue text-left"
                    scope="col">Sous-catégorie</th>
                <th class="tht-dark-blue"
                    scope="col">Montant prévisionnel (FCFA)</th>
                <th class="tht-dark-blue"
                    scope="col">Engagements</th>
                <th class="tht-dark-blue"
                    scope="col">Décomptes reçus</th>
                <th class="tht-dark-blue"
                    scope="col">Paiements effectués</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à recevoir</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à payer sur décomptes reçus</th>
                <th class="tht-dark-blue"
                    scope="col">Restes à payer sur engagements</th>
              </tr>
            </thead>
            <template v-for="(sous_total,stkey) in sous_totaux">
              <tbody :key="stkey">
                <template v-for="(program,programKey) in decomptePer">
                  <tr scope="row"
                      :key="programKey"
                      @click="launchUpdate(program.id)"
                      v-if="sous_total.libelle==program.rubrique">
                    <td class="text-left">{{program.rubrique}}</td>
                    <td class="text-left">{{program.programme}}</td>
                    <td class="text-right">{{Math.round(Number(program.montant)).toLocaleString()}}</td>
                    <td class="text-right">{{Math.round(Number(program.engagement)).toLocaleString()}}</td>
                    <td class="text-right">{{Math.round(Number(program.decompte_recu)).toLocaleString()}}</td>
                    <td class="text-right">{{Math.round(Number(program.decompte_paye)).toLocaleString()}}</td>
                    <td class="text-right">{{Math.round(Number(program.engagement-program.decompte_recu)).toLocaleString() }}</td>
                    <td class="text-right">{{Math.round(Number(program.decompte_en_attente_payement)).toLocaleString() }}</td>
                    <td class="text-right">{{Math.round(Number((program.engagement-program.decompte_recu)+program.decompte_en_attente_payement)).toLocaleString() }}</td>

                  </tr>
                </template>
                <tr scope="row"
                    class="tht-blue">
                  <td class="text-left text-uppercase"
                      colspan="2">S/Total {{ sous_total.libelle }}</td>
                  <td class="text-right">{{Math.round(Number(sous_total.montant)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(sous_total.engagement)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(sous_total.recu)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(sous_total.paye)).toLocaleString()}}</td>
                  <td class="text-right">{{Math.round(Number(sous_total.engagement-sous_total.recu)).toLocaleString()}}</td>  
                  <td class="text-right">{{Math.round(Number(sous_total.en_attente_payement)).toLocaleString()}}</td>  
                  <td class="text-right">{{Math.round(Number((sous_total.engagement-sous_total.recu)+sous_total.en_attente_payement)).toLocaleString()}}</td>  
                </tr>
              </tbody>
            </template>
            <tfoot>
              <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
              <tr scope="row">
                <td class="tht-dark-blue text-left text-uppercase"
                    colspan="2">Total</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.engagement)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.engagement-totaux.recu)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
                <td class="tht-dark-blue text-right">{{Math.round(Number((totaux.engagement-totaux.recu)+totaux.en_attente_payement)).toLocaleString()}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        
      </div>
    </vue-html2pdf>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="row mx-auto mt-2">
      <table class="table table-blue text-center"> 
        <thead>
          <tr>
            <th class="tht-dark-blue text-left"
                scope="col">Catégorie</th>
            <th class="tht-dark-blue text-left"
                scope="col">Sous-catégorie</th>
            <th class="tht-dark-blue"
                scope="col">Montant prévisionnel (FCFA)</th>
            <th class="tht-dark-blue"
                scope="col">Engagements</th>
            <th class="tht-dark-blue"
                scope="col">Décomptes reçus</th>
            <th class="tht-dark-blue"
                scope="col">Paiements effectués</th>
            <th class="tht-dark-blue"
                scope="col">Restes à recevoir</th>
            <th class="tht-dark-blue"
                scope="col">Restes à payer sur décomptes reçus</th>
            <th class="tht-dark-blue"
                scope="col">Restes à payer sur engagements</th>
          </tr>
        </thead>
        <template v-for="(sous_total,stkey) in sous_totaux">
          <tbody :key="stkey">
            <template v-for="(program,programKey) in decomptePer">
              <tr scope="row"
                  :key="programKey"
                  @click="launchUpdate(program.id)"
                  v-if="sous_total.libelle==program.rubrique">
                <td class="text-left">{{program.rubrique}}</td>
                <td class="text-left">{{program.programme}}</td>
                <td class="text-right">{{Math.round(Number(program.montant)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.engagement)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.decompte_recu)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.decompte_paye)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(program.engagement-program.decompte_recu)).toLocaleString() }}</td>
                <td class="text-right">{{Math.round(Number(program.decompte_en_attente_payement)).toLocaleString() }}</td>
                <td class="text-right">{{Math.round(Number((program.engagement-program.decompte_recu)+program.decompte_en_attente_payement)).toLocaleString() }}</td>

              </tr>
            </template>
            <tr scope="row"
                class="tht-blue">
              <td class="text-left text-uppercase"
                  colspan="2">S/Total {{ sous_total.libelle }}</td>
              <td class="text-right">{{Math.round(Number(sous_total.montant)).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(sous_total.engagement)).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(sous_total.recu)).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(sous_total.paye)).toLocaleString()}}</td>
              <td class="text-right">{{Math.round(Number(sous_total.engagement-sous_total.recu)).toLocaleString()}}</td>  
              <td class="text-right">{{Math.round(Number(sous_total.en_attente_payement)).toLocaleString()}}</td>  
              <td class="text-right">{{Math.round(Number((sous_total.engagement-sous_total.recu)+sous_total.en_attente_payement)).toLocaleString()}}</td>  
            </tr>
          </tbody>
        </template>
        <tfoot>
          <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
          <tr scope="row">
            <td class="tht-dark-blue text-left text-uppercase"
                colspan="2">Total</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.engagement)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.engagement-totaux.recu)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
            <td class="tht-dark-blue text-right">{{Math.round(Number((totaux.engagement-totaux.recu)+totaux.en_attente_payement)).toLocaleString()}}</td>
          </tr>
        </tfoot>
      </table>

    </div>
  </div>
</template>
<style>
  /* .table tbody td,
  .table tfoot td,
  .table thead th,
  .form-inline input.form-control,
  .form-inline button.btn-info
  {
    font-size: 0.54em;
  } */
  .table tbody tr{
    cursor: pointer;
  }
</style>
<script>
import {mapActions, mapGetters} from "vuex"
import VueHtml2pdf from 'vue-html2pdf'

export default({
  name:"ActiviteBoard",
  components: {
    VueHtml2pdf,
  },
  data:() =>({
    decomptePer:[],
    index:1,
    dateJour:"",
    loaded:false,
    annee_debut:new Date().getFullYear()+"-01-01",
    annee_fin:new Date().getFullYear()+"-12-31",
    exportation:{
      titre:"Situation des sous-categories du programme",
      contenu:[],
      entete:[
        "Catégorie",
        "Sous-catégorie",
        "Montant prévisionnel",
        "Engagements",
        "Décomptes reçus",
        "Paiements effectués",
        "Reste à recevoir",
        "Reste à payer sur décomptes reçus",
        "Reste à payer sur engagements"
      ],
      nom_utilisateur:"",
      departement:"",
      date_impression:"",
      totaux:{
        rubrique:"Totaux",
        programme:"",
        montant:0,
        decompte_recu:0,
        reste_recevoir:0,
        decompte_paye:0,
        engagement:0,
        decompte_en_attente_payement:0,
        // decompte_en_attente_payement_anterieure:0,
        reste_payer_engagement:0,
      }
    },
    totaux:{
      montant:0,
      recu:0,
      engagement:0,
      paye:0,
      en_attente_payement:0,
      en_attente_payement_anterieure:0
    },
    sous_totaux:[{
      montant:0,
      recu:0,
      engagement:0,
      paye:0,
      en_attente_payement:0,
      en_attente_payement_anterieure:0
    }]
  }),
  watch:{
    TableauDecomptePer(){
      this.decomptePer = this.TableauDecomptePer.donnees
      // console.log(this.decomptePer)
      this.sous_totaux=[]
      this.exportation.contenu=[]
      var list_rubrique=[]
      this.totaux.montant=0
      this.totaux.recu=0
      this.totaux.engagement=0
      this.totaux.paye=0
      this.totaux.en_attente_payement=0
      this.totaux.en_attente_payement_anterieure=0
      this.TableauDecomptePer.donnees.forEach(per => {
        if(!list_rubrique.includes(per.rubrique)){
          list_rubrique.push(per.rubrique)
        }
        this.totaux.recu+= per.decompte_recu
        // console.log(this.totaux)
        this.totaux.montant+= per.montant
        this.totaux.paye+= per.decompte_paye
        this.totaux.engagement+= per.engagement
        this.totaux.en_attente_payement+= per.decompte_en_attente_payement
        this.totaux.en_attente_payement_anterieure+= per.decompte_en_attente_payement_anterieure
      })
      for (let index = 0; index < list_rubrique.length; index++) {
        this.sous_totaux.push({
          libelle:list_rubrique[index],
          montant:0,
          recu:0,
          engagement:0,
          paye:0,
          en_attente_payement:0,
          en_attente_payement_anterieure:0
        })
        this.TableauDecomptePer.donnees.forEach(per => {
          if(list_rubrique[index]==per.rubrique){
            this.sous_totaux[index].recu+= per.decompte_recu
            // console.log(this.totaux)
            this.sous_totaux[index].montant+= per.montant
            this.sous_totaux[index].engagement+= per.engagement
            this.sous_totaux[index].paye+= per.decompte_paye
            this.sous_totaux[index].en_attente_payement+= per.decompte_en_attente_payement
            this.sous_totaux[index].en_attente_payement_anterieure+= per.decompte_en_attente_payement_anterieure
            
            this.exportation.contenu.push({
              rubrique : per.rubrique,
              programme : per.programme,
              decompte_recu : per.decompte_recu,
              montant : per.montant,
              decompte_paye : per.decompte_paye,
              engagement : per.engagement,
              decompte_en_attente_payement : per.decompte_en_attente_payement,
              // decompte_en_attente_payement_anterieure : per.decompte_en_attente_payement_anterieure,
              reste_recevoir : Number(per.engagement-per.decompte_recu),
              reste_payer_engagement : Number(per.engagement-per.decompte_recu) + Number(per.decompte_en_attente_payement),
            })
          }
        })
        this.exportation.contenu.push({
          rubrique : "S/TOTAL "+this.sous_totaux[index].libelle,
          programme : "",
          decompte_recu : this.sous_totaux[index].recu,
          montant : this.sous_totaux[index].montant,
          decompte_paye : this.sous_totaux[index].paye,
          engagement : this.sous_totaux[index].engagement,
          decompte_en_attente_payement : this.sous_totaux[index].en_attente_payement,
          // decompte_en_attente_payement_anterieure : per.decompte_en_attente_payement_anterieure,
          reste_recevoir : Number(this.sous_totaux[index].engagement-this.sous_totaux[index].recu),
          reste_payer_engagement : Number(this.sous_totaux[index].engagement-this.sous_totaux[index].recu) + Number(this.sous_totaux[index].en_attente_payement),
        })
        
      }
      this.exportation.totaux.montant=this.totaux.montant
      this.exportation.totaux.decompte_paye=this.totaux.paye
      this.exportation.totaux.decompte_recu=this.totaux.recu
      this.exportation.totaux.engagement=this.totaux.engagement
      this.exportation.totaux.decompte_en_attente_payement=this.totaux.en_attente_payement
      // this.exportation.totaux.decompte_en_attente_payement_anterieure=this.totaux.en_attente_payement_anterieure
      this.exportation.totaux.reste_recevoir= Number(this.totaux.engagement-this.totaux.recu)
      this.exportation.totaux.reste_payer_engagement=Number((this.totaux.engagement-this.totaux.recu)+this.totaux.en_attente_payement)
      this.loaded=true
      this.exporterDonnees(this.exportation)
      console.log(this.sous_totaux)
    },
  },
  created() {
    var jour = new Date()
    this.dateJour=""
    if (jour.getDate() < 10) {
      this.dateJour ="0" 
    }
    this.dateJour+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    this.dateJour += jour.getFullYear()
    
    this.loaded=false
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    // remplir la case date_impression pour l'exportation
    this.exportation.date_impression = this.dateJour    
    // remplir la case nom_utilisateur pour l'exportation
    this.exportation.nom_utilisateur = userdetails.nom
    
    // remplir la case departement pour l'exportation
    switch (this.user[0]) {
      case "DT":
        this.exportation.departement = "Direction Technique"
        break
      case "DCG":
        this.exportation.departement = "Direction du Contrôle de Gestion"
        break
      case "DAF":
        this.exportation.departement = "Direction Administrative et Financière/ Ingenierie Financière"
        break
      case "ADMIN":
        this.exportation.departement = "Administrateur"
        break
      default:        
        this.exportation.departement = "Autre"
        break
    }
    this.getTableauDecomptePer({
      annee_debut:this.annee_debut,
      annee_fin:this.annee_fin,
      charge:"activites_programmes",
      id_programme:this.$route.params.id
    })
    this.getProgramme(this.$route.params.id)
  },
  computed:{
    ...mapGetters(["assignProgramme","TableauDecomptePer","exportLink","failExportLink"])
  },
  methods:{
    ...mapActions(["getProgramme","getTableauDecomptePer","exporterDonnees"]),
    launchUpdate(id) {
      this.$router.push({ name: "MarcheBoard", params: { id: id } })
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    changeYear(){
      this.loaded = false
      this.getTableauDecomptePer({
        annee_debut:this.annee_debut,
        annee_fin:this.annee_fin,
        charge:"activites_programmes",
        id_programme:this.$route.params.id
      })
      this.getProgramme(this.$route.params.id)
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
})
</script>
